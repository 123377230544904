<template>
  <div
    class="grid col-12 font table-separate"
    style="margin-bottom: 10px; padding: 10px"
    v-for="(item, index) in dados"
    v-show="item.tipo_segmento === 1 || item.tipo_segmento === 2"
    :key="index"
  >
    <div class="grid justify-content-between align-items-center col-12" v-if="cabecalho">
      <div class="col-5 align-content-center">
        <img
          style="width: auto; height: 100px;"
          :src="base_url + '/storage/' + cabecalho.logo"
          alt=""
          class="img"
        />
      </div>

      <div
        style="margin: 0px !important;"
        v-html="cabecalho.texto_logo"
        class="cabecalho font-cabecalho mr-5"
      ></div>
    </div>


    <table class="col-12 mt-2 borda-externa" style="min-width: 1500px;">
      <thead class="cor-titulo">
        <tr align="center">
          <th colspan="3">FICHA INDIVIDUAL - {{ item.segmento_escolar }}</th>
        </tr>
      </thead>

      <tbody class="borda-externa">
        <tr>
          <td class="">Aluno(a): {{ item.aluno_nome }}</td>
          <td class="">Data de Nascimento: {{ item.data_nascimento }}</td>
        </tr>

        <tr>
          <td class="">Unidade Escolar: {{ item.escola_nome }}</td>
          <td class="">CPF: {{ item.cpf }}</td>
        </tr>

        <tr>
          <td class="">
            Ano de Escolaridade: {{ item.ano_de_escolaridade }}
            <span v-for="(serie, index) in series" :key="index">
              (){{ serie.nome }}</span
            >
          </td>
          <td class="">RG: {{ item.rg }}</td>
        </tr>

        <tr>
          <td class="">Turno: {{ item.turno }}</td>
          <td class="borda-">Naturalidade: {{ item.naturalidade }}</td>
        </tr>

        <tr>
          <td class="borda-">Turma: {{ item.turma }}</td>
          <td class="borda-">Mãe: {{ item.mae }}</td>
        </tr>
        <tr>
          <td class="borda-">Ano Letivo: {{ item.anoletivo }}</td>
          <td class="borda-">Pai: {{ item.pai }}</td>
        </tr>
        <tr>
          <td class="borda-">
            Total de Dias Letivos: {{ item.total_dias_letivos }}
          </td>
          <td class="borda-">Endereço: {{ item.endereco.logradouro }}</td>
        </tr>
      </tbody>
    </table>

    <table class="col-12 borda-externa tabela" style="min-width: 1500px;" border="1">
      <thead>
        <tr align="center">
          <th colspan="19">BASE NACIONAL COMUM CURRICULAR</th>
        </tr>
        <tr align="center">
          <th rowspan="2" class="bordas title-padding">AREA DE CONHECIMENTO</th>
          <th rowspan="2" class="bordas title-padding borda-right">
            COMPONENTES CURRICULARES
          </th>
          <th colspan="3" class="bordas title-padding borda-right">
            1° período
          </th>
          <th colspan="3" class="bordas title-padding borda-right">
            2° período
          </th>
          <th colspan="3" class="bordas title-padding borda-right">
            3° período
          </th>
          <th colspan="3" class="bordas title-padding borda-right">
            4° período
          </th>
          <td rowspan="2" class="bordas title-padding">Nota Parc. Final</td>
          <td rowspan="2" class="bordas title-padding">Rec. Final</td>
          <td rowspan="2" class="bordas title-padding">Resul. Anual</td>
          <td rowspan="2" class="bordas title-padding">Total Aulas Dadas</td>
          <td rowspan="2" class="bordas title-padding" style="max-width: 100px;">Total de Frequência</td>

          <td rowspan="2" class="bordas title-padding">% frequencia</td>
        </tr>
        <tr align="center">
          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding borda-right">Frequência</td>

          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding borda-right">Frequência</td>

          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding borda-right">Frequência</td>

          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding borda-right">Frequência</td>
        </tr>
      </thead>

      <tbody>
        <tr
          class=""
          align="center"
          v-for="(componente, index) in item.info_disciplina_por_area"
          :key="index"
        >
          <td class="borda-top-1px borda-right-1px">
            <div class="borda-top-1px" >
              {{ index }}
            </div>
          </td>

          <td class="borda-top-1px borda-right" >
            <div
              class="borda-top-1px linhas"

              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              {{ disciplina.nome_componente }}
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px" >
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"

            >
              <span v-if="disciplina.p1_letivo">{{ disciplina.p1_letivo.nota }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p1_letivo">{{ disciplina.p1_letivo.aulas_dadas }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p1_letivo">{{ disciplina.p1_letivo.presenca }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p2_letivo">{{ disciplina.p2_letivo.nota}}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p2_letivo">{{ disciplina.p2_letivo.aulas_dadas }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p2_letivo">{{ disciplina.p2_letivo.presenca }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p3_letivo">{{ disciplina.p3_letivo.nota }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p3_letivo">{{ disciplina.p3_letivo.aulas_dadas }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p3_letivo">{{ disciplina.p3_letivo.presenca }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p4_letivo">{{ disciplina.p4_letivo.nota }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p4_letivo">{{ disciplina.p4_letivo.aulas_dadas }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p4_letivo">{{ disciplina.p4_letivo.presenca }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              {{ disciplina.nota_final }}
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.calculo_geral.recuperacao_final == null"
              >-</span
              >
              <span v-else>{{
                disciplina.calculo_geral.recuperacao_final
              }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :style="disciplina.calculo_geral.resultado_anual < 50 ? 'color: red' : 'color:blue'"
              :key="index2"
            >
              {{ disciplina.calculo_geral.resultado_anual }}
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              {{ disciplina.calculo_geral.total_aulas_dadas }}
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              {{ disciplina.calculo_geral.total_frequencia }}
            </div>
          </td>

          <td class="borda-top-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              {{ disciplina.calculo_geral.porcentagem_frequencia }}
            </div>
          </td>
        </tr>

        <tr align="center" class="borda-top">
          <td
            class="borda-bottom borda-right-1px"
            colspan="2"
            style="padding: 10px !important"
          >
            Cálculo Global de Aulas Dadas:
          </td>
          <td colspan="2" class="borda-bottom borda-right-1px" style="padding: 10px !important">
            {{ item.calculo_global_aulas }}
          </td>

          <td class="borda-bottom borda-right-1px" colspan="5" style="padding: 10px !important">
            Cálculo Global de Frequência:
          </td>
          <td class="borda-bottom borda-right-1px" colspan="3" style="padding: 10px !important">
            {{ item.calculo_global_frequencia }}
          </td>

          <td class="borda-bottom borda-right-1px" colspan="5" style="padding: 10px !important">
            Percentual Global de Frequência:
          </td>

          <td
            class="borda-bottom borda-right-1px"
            colspan="3"
            style="padding: 10px !important"
            v-if="item.controle_de_frequencia.percentual_frequencia >= '75%'"
          >
            +75
          </td>
          <td
            class="borda-bottom borda-right-1px"
            colspan="3"
            style="padding: 10px !important"
            v-else-if="
              item.controle_de_frequencia.percentual_frequencia == '100%'
            "
          >
            +75
          </td>
          <td class="borda-bottom borda-right-1px" colspan="3" style="padding: 10px !important" v-else>-75</td>
        </tr>
        <!-- <td class="bordas borda-right">
            {{ componente.nome_componente }}
          </td>

          <td class="bordas">
            {{ componente.p1_letivo.nota }}
          </td>
          <td class="bordas">
            {{ componente.p1_letivo.aulas_dadas }}
          </td>
          <td class="bordas borda-right">
            {{ componente.p1_letivo.presenca }}
          </td>

          <td class="bordas">
            {{ componente.p2_letivo.nota }}
          </td>
          <td class="bordas">
            {{ componente.p2_letivo.aulas_dadas }}
          </td>
          <td class="bordas borda-right">
            {{ componente.p2_letivo.presenca }}
          </td>

          <td class="bordas">
            {{ componente.p3_letivo.nota }}
          </td>
          <td class="bordas">
            {{ componente.p3_letivo.aulas_dadas }}
          </td>
          <td class="bordas borda-right">
            {{ componente.p3_letivo.presenca }}
          </td>

          <td class="bordas">
            {{ componente.p4_letivo.nota }}
          </td>
          <td class="bordas">
            {{ componente.p4_letivo.aulas_dadas }}
          </td>
          <td class="bordas borda-right">
            {{ componente.p4_letivo.presenca }}
          </td>

          <td class="bordas">{{ componente.nota_final }}</td>
          <td
            v-if="componente.calculo_geral.recuperacao_final == null"
            class="bordas"
          >
            -
          </td>
          <td v-else class="bordas">
            {{ componente.calculo_geral.recuperacao_final }}
          </td>
          <td class="bordas">{{ componente.calculo_geral.resultado_anual }}</td>
          <td class="bordas">
            {{ componente.calculo_geral.total_aulas_dadas }}
          </td>
          <td class="bordas">
            {{ componente.calculo_geral.total_frequencia }}
          </td>
          <td class="bordas">
            {{ componente.calculo_geral.porcentagem_frequencia }}
          </td>
        </tr>
        <tr align="center" class="borda-top">
          <td
            class="bordas borda-bottom"
            colspan="2"
            style="padding: 10px !important"
          >
            Cálculo Global de Aulas Dadas:
          </td>
          <td colspan="2" class="bordas borda-bottom">
            {{ item.calculo_global_aulas }}
          </td>

          <td class="bordas borda-bottom" colspan="5">
            Cálculo Global de Frequência:
          </td>
          <td class="bordas borda-bottom" colspan="3">
            {{ item.calculo_global_frequencia }}
          </td>

          <td class="bordas borda-bottom" colspan="5">
            Percentual Global de Frequência:
          </td>

          <td
            class="bordas borda-bottom"
            colspan="3"
            v-if="item.controle_de_frequencia.percentual_frequencia >= '75%'"
          >
            +75
          </td>
          <td class="bordas borda-bottom" colspan="3" v-else>-75</td>
        </tr> -->

        <!-- fund1 e ERN -->
        <tr v-if="item.tipo_segmento === 1" align="center">
          <th colspan="17" rowspan="2" class="bordas">
            Legenda: {{nomenclaturaSituacaoAluno.aprovado}} ({{nomenclaturaSituacaoAluno.aprovadoAbreviacao}}),
            {{ nomenclaturaSituacaoAluno.aprovadoPeloConselho }} ({{ nomenclaturaSituacaoAluno.aprovadoPeloConselhoAbreviacao }}),
            {{ nomenclaturaSituacaoAluno.aprovadoCreche }} ({{ nomenclaturaSituacaoAluno.aprovadoCrecheAbreviacao }}),
            {{nomenclaturaSituacaoAluno.reprovado}} ({{nomenclaturaSituacaoAluno.reprovadoAbreviacao}}),
            {{ nomenclaturaSituacaoAluno.transferido }} ({{ nomenclaturaSituacaoAluno.transferidoAbreviacao }}),
            {{ nomenclaturaSituacaoAluno.transferenciaExternaCreche }} ({{ nomenclaturaSituacaoAluno.transferenciaExternaCrecheAbreviacao }}),
            {{ nomenclaturaSituacaoAluno.evadido }} ({{ nomenclaturaSituacaoAluno.evadidoAbreviacao }}),
            {{ nomenclaturaSituacaoAluno.falecido }} ({{ nomenclaturaSituacaoAluno.falecidoAbreviacao }})
          </th>
          <th
            class="bordas"
            rowspan="1"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
          >
            RESULTADO FINAL
          </th>
        </tr>
        <tr v-if="item.tipo_segmento === 1" align="center">
          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'aprovado'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.aprovado}}
          </th>
          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'apc'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.aprovadoPeloConselho}}
          </th>

          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'reprovado'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.reprovado}}
          </th>

          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'transferido'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.transferido}}
          </th>

          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'evadido'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.evadido}}
          </th>
          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'falecido'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.falecido}}
          </th>

        </tr>
        <tr v-if="item.tipo_segmento === 1" align="right">
          <td colspan="17" style="padding-top: 1px;">
            São José de Ubá, ________de______________ de dois mil e vinte e
            ____________.
          </td>
        </tr>

        <!-- fund 2 -->
        <tr v-if="item.tipo_segmento === 2" align="center">
          <td colspan="2" class="bordas">PROGRESSÃO PARCIAL</td>
          <td colspan="3" class="bordas">Componentes Curriculares</td>
          <td colspan="3" class="bordas">Ano de Escolaridade</td>
          <td colspan="3" class="bordas">AULAS DADAS</td>
          <td colspan="3" class="bordas">Percentual Frequência</td>
          <td colspan="3" class="bordas">Ratificação Supervisor</td>
          <th
            class="bordas"
            rowspan="1"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
          >
            RESULTADO FINAL
          </th>
        </tr>

        <tr v-if="item.tipo_segmento === 2" align="center">
          <td colspan="2" rowspan="1" class="bordas">Dependência</td>
          <td colspan="3" class="bordas"></td>
          <td colspan="3" class="bordas"></td>
          <td colspan="3" class="bordas"></td>
          <td colspan="3" class="bordas"></td>
          <td colspan="3" class="bordas"></td>
          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'aprovado'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.aprovado}}
          </th>
          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'apc'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.aprovadoPeloConselho}}
          </th>

          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'reprovado'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.reprovado}}
          </th>

          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'transferido'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.transferido}}
          </th>

          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'evadido'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.evadido}}
          </th>
          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'falecido'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.falecido}}
          </th>
        </tr>
        <tr v-if="item.tipo_segmento === 2" align="center">
          <td colspan="2" rowspan="1" class="borda-bottom borda-right-1px">
            Dependência
          </td>

          <td colspan="3" class="borda-bottom borda-right-1px"></td>
          <td colspan="3" class="borda-bottom borda-right-1px"></td>
          <td colspan="3" class="borda-bottom borda-right-1px"></td>
          <td colspan="3" class="borda-bottom borda-right-1px"></td>
          <td colspan="3" class="borda-bottom borda-right-1px"></td>
        </tr>
        <tr v-if="item.tipo_segmento === 2" align="center">
          <th colspan="19" class="">
            Legenda: {{nomenclaturaSituacaoAluno.aprovado}}-({{nomenclaturaSituacaoAluno.aprovadoAbreviacao}}),
            {{ nomenclaturaSituacaoAluno.aprovadoPeloConselho }}-({{ nomenclaturaSituacaoAluno.aprovadoPeloConselhoAbreviacao }}),
            {{ nomenclaturaSituacaoAluno.aprovadoCreche }}-({{ nomenclaturaSituacaoAluno.aprovadoCrecheAbreviacao }}),
            {{nomenclaturaSituacaoAluno.reprovado}}-({{nomenclaturaSituacaoAluno.reprovadoAbreviacao}}),
            {{ nomenclaturaSituacaoAluno.transferido }}-({{ nomenclaturaSituacaoAluno.transferidoAbreviacao }}),
            {{ nomenclaturaSituacaoAluno.transferenciaExternaCreche }}-({{ nomenclaturaSituacaoAluno.transferenciaExternaCrecheAbreviacao }}),
            {{ nomenclaturaSituacaoAluno.evadido }}-({{ nomenclaturaSituacaoAluno.evadidoAbreviacao }}),
            {{ nomenclaturaSituacaoAluno.falecido }}-({{ nomenclaturaSituacaoAluno.falecidoAbreviacao }})
          </th>
        </tr>
        <tr v-if="item.tipo_segmento === 2" align="right">
          <td colspan="19" style="padding-top: 15px;">
            São José de Ubá, ________de______________ de dois mil e vinte e
            ____________.
          </td>
        </tr>
      </tbody>
    </table>

    <table class="col-12 borda-externa" style="min-width: 1500px;">
      <tbody>
        <tr>
          <td colspan="3">Observação:
            <label v-if="item.resultado_final === 'apc' && item.numeroAta" >Aprovado pela ata {{ item.numeroAta }}</label> <br>
            {{ item.observacao_geral }}</td>
        </tr>

        <tr align="center">
          <td>___________________</td>
          <td>___________________</td>
          <td>___________________</td>
        </tr>
        <tr align="center">
          <td>Secretário (a)</td>
          <td>Diretor (a)</td>
          <td>Supervisor (a)</td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- <div
    class="grid col-12 font"
    style="margin-bottom: 10px; padding: 10px"
    v-for="(item, index) in dados"
    :key="index"
  >
    <table class="col-12 mt-2 borda-externa">
      <thead class="cor-titulo">
        <tr align="center">
          <th colspan="3">FICHA INDIVIDUAL - {{ item.segmento_escolar }}</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>Total de Dias Letivos: {{ item.total_dias_letivos }}</td>
          <td>Ano Letivo: {{ item.anoletivo }}</td>
        </tr>

        <tr>
          <td>Unidade Escolar: {{ item.escola_nome }}</td>
          <td>Endereço: {{ item.endereco.logradouro }}</td>
        </tr>

        <tr>
          <td>Aluno(a): {{ item.aluno_nome }}</td>
          <td>Data de Nascimento: {{ item.data_nascimento }}</td>
          <td>Naturalidade: {{ item.naturalidade }}</td>
        </tr>

        <tr>
          <td>Mãe: {{ item.mae }}</td>
          <td>Pai: {{ item.pai }}</td>
        </tr>

        <tr>
          <td>
            Ano de Escolaridade: {{ item.ano_de_escolaridade }}
            <span v-for="(serie, index) in series" :key="index">
              (){{ serie.nome }}</span
            >
          </td>
          <td>Turma: {{ item.turma }}</td>
          <td>Turno: {{ item.turno }}</td>
        </tr>
      </tbody>
    </table>

    <table class="col-12 borda-externa tabela">
      <thead>
        <tr align="center">
          <th rowspan="2" class="bordas title-padding borda-right">
            COMPONENTES CURRICULARES
          </th>
          <th colspan="3" class="bordas title-padding borda-right">
            1° periodo
          </th>
          <th colspan="3" class="bordas title-padding borda-right">
            2° periodo
          </th>
          <th colspan="3" class="bordas title-padding borda-right">
            3° periodo
          </th>
          <th colspan="3" class="bordas title-padding borda-right">
            4° periodo
          </th>
          <td rowspan="2" class="bordas title-padding">Nota Parc. Final</td>
          <td rowspan="2" class="bordas title-padding">Rec. Final</td>
          <td rowspan="2" class="bordas title-padding">Resul. Anual</td>
          <td rowspan="2" class="bordas title-padding">Total Aulas de Dadas</td>
          <td rowspan="2" class="bordas title-padding">Total de Frequência</td>
          <td rowspan="2" class="bordas title-padding">
            Cálculo Global de Aulas Dadas
          </td>
          <td rowspan="2" class="bordas title-padding">
            Cálculo Global de Frequência
          </td>
          <td rowspan="2" class="bordas title-padding">% frequencia</td>
          <td rowspan="2" class="bordas title-padding">Visto Supervisor</td>
        </tr>
        <tr align="center">
          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding borda-right">Frequência</td>

          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding borda-right">Frequência</td>

          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding borda-right">Frequência</td>

          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding borda-right">Frequência</td>
        </tr>
      </thead>

      <tbody>
        <tr
          align="center"
          v-for="(componente, index) in item.componentes_curriculares"
          :key="index"
        >
          <td class="bordas borda-right">
            {{ componente.nome_componente }}
          </td>

          <td class="bordas">
            {{ componente.p1_letivo.nota }}
          </td>
          <td class="bordas">
            {{ componente.p1_letivo.aulas_dadas }}
          </td>
          <td class="bordas borda-right">
            {{ componente.p1_letivo.presenca }}
          </td>

          <td class="bordas">
            {{ componente.p2_letivo.nota }}
          </td>
          <td class="bordas">
            {{ componente.p2_letivo.aulas_dadas }}
          </td>
          <td class="bordas borda-right">
            {{ componente.p2_letivo.presenca }}
          </td>

          <td class="bordas">
            {{ componente.p3_letivo.nota }}
          </td>
          <td class="bordas">
            {{ componente.p3_letivo.aulas_dadas }}
          </td>
          <td class="bordas borda-right">
            {{ componente.p3_letivo.presenca }}
          </td>

          <td class="bordas">
            {{ componente.p4_letivo.nota }}
          </td>
          <td class="bordas">
            {{ componente.p4_letivo.aulas_dadas }}
          </td>
          <td class="bordas borda-right">
            {{ componente.p4_letivo.presenca }}
          </td>

          <td class="bordas">{{ componente.nota_final }}</td>
          <td
            v-if="componente.calculo_geral.recuperacao_final == null"
            class="bordas"
          >
            -
          </td>
          <td v-else class="bordas">
            {{ componente.calculo_geral.recuperacao_final }}
          </td>
          <td class="bordas">{{ componente.calculo_geral.resultado_anual }}</td>
          <td class="bordas">
            {{ componente.calculo_geral.total_aulas_dadas }}
          </td>
          <td class="bordas">
            {{ componente.calculo_geral.total_frequencia }}
          </td>
          <td class="bordas">{{ item.calculo_global_aulas }}</td>
          <td class="bordas">{{ item.calculo_global_frequencia }}</td>
          <td class="bordas">
            {{ componente.calculo_geral.porcentagem_frequencia }}%
          </td>
          <td rowspan=""></td>
        </tr>

        <tr v-if="info.segmento_id.tipo === 1">
          <th colspan="17" class="bordas borda-top">
            Legenda: Promovido-(Pr), Retido-(Ret), Evadido-(Ev) ou
            Transferido-(Tr), Conteúdo Explorado-(CE).
          </th>
          <th
            class="bordas borda-top"
            colspan="5"
            style="text-transform: uppercase"
            v-if="item.resultado_final === 'reprovado'"
          >
            RESULTADO FINAL: Ret
          </th>
          <th
            class="bordas borda-top"
            colspan="5"
            style="text-transform: uppercase"
            v-if="item.resultado_final === 'aprovado'"
          >
            RESULTADO FINAL: Pr
          </th>
          <th
            class="bordas borda-top"
            colspan="5"
            style="text-transform: uppercase"
            v-if="item.resultado_final === 'evadido'"
          >
            RESULTADO FINAL: Ev
          </th>
          <th
            class="bordas borda-top"
            colspan="5"
            style="text-transform: uppercase"
            v-if="item.resultado_final === 'Transferido'"
          >
            RESULTADO FINAL: Tr
          </th>
        </tr>

        <tr v-if="info.segmento_id.tipo === 2" align="center" class="borda-top">
          <td colspan="2" class="bordas">PROGRESSÃO PARCIAL</td>
          <td colspan="3" class="bordas">Componentes Curriculares</td>
          <td colspan="3" class="bordas">Ano de Escolaridade</td>
          <td colspan="3" class="bordas">AULAS DADAS</td>
          <td colspan="3" class="bordas">Percentual Frequência</td>
          <td colspan="3" class="bordas">Ratificação Supervisor</td>
          <th
            class="bordas borda-bottom"
            rowspan="3"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
            v-if="item.resultado_final === 'reprovado'"
          >
            RESULTADO FINAL: Ret
          </th>
          <th
            class="bordas borda-bottom"
            rowspan="3"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
            v-if="item.resultado_final === 'aprovado'"
          >
            RESULTADO FINAL: Pr
          </th>
          <th
            class="bordas borda-bottom"
            rowspan="3"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
            v-if="item.resultado_final === 'evadido'"
          >
            RESULTADO FINAL: Ev
          </th>
          <th
            class="bordas borda-bottom"
            rowspan="3"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
            v-if="item.resultado_final === 'Transferido'"
          >
            RESULTADO FINAL: Tr
          </th>
          <th colspan="2" rowspan="3" class="borda-bottom">
            Percentual Global de frequência: <br />
            {{ item.controle_de_frequencia.percentual_frequencia }}%
          </th>
        </tr>

        <tr v-if="info.segmento_id.tipo === 2" align="center">
          <td colspan="2" rowspan="1" class="bordas">Dependencia(s)</td>
          <td colspan="3" class="bordas"></td>
          <td colspan="3" class="bordas"></td>
          <td colspan="3" class="bordas"></td>
          <td colspan="3" class="bordas"></td>
          <td colspan="3" class="bordas"></td>
        </tr>
        <tr v-if="info.segmento_id.tipo === 2" align="center">
          <td colspan="2" rowspan="1" class="bordas borda-bottom">
            Dependencia(s)
          </td>

          <td colspan="3" class="bordas borda-bottom"></td>
          <td colspan="3" class="bordas borda-bottom"></td>
          <td colspan="3" class="bordas borda-bottom"></td>
          <td colspan="3" class="bordas borda-bottom"></td>
          <td colspan="3" class="bordas borda-bottom"></td>
        </tr>
        <tr v-if="info.segmento_id.tipo === 2">
          <th colspan="22" class="bordas">
            Legenda: Promovido-(Pr), Retido-(Ret), Evadido-(Ev) ou
            Transferido-(Tr), Conteúdo Explorado-(CE).
          </th>
        </tr>
      </tbody>
    </table>

    <table class="col-12 borda-externa">
      <tbody>
        <tr>
          <td colspan="3">Observação: {{ item.observacao_geral }}</td>
        </tr>

        <tr align="right">
          <td colspan="3">
            Cardoso Moreira, ________de______________ de dois mil e vinte e
            ____________.
          </td>
        </tr>

        <tr align="center">
          <td>___________________</td>
          <td>___________________</td>
          <td>___________________</td>
        </tr>
        <tr align="center">
          <td>Secretário (a)</td>
          <td>Diretor (a)</td>
          <td>Supervisor (a)</td>
        </tr>
      </tbody>
    </table>
  </div> -->
</template>

<script>
import { defineComponent, } from "vue";
import { nomenclaturaSituacaoAluno } from '@/utils/nomenclatura';
import axios from "axios";


export default defineComponent({
  components: {},
  props: {
    cabecalho: {},
    dados: "",
    info: {},
  },
  data() {
    return {
      base_url: axios.defaults.baseURL.replace("/api/v1", ""),
      nomenclaturaSituacaoAluno: nomenclaturaSituacaoAluno,
      parteDiversificada: {
        area: "",
        calculo_geral: {
          nota_parcial_final: "CE",
          porcentagem_frequencia: "CE",
          recuperacao_final: "CE",
          resultado_anual: "CE",
          total_aulas_dadas: "CE",
          total_frequencia: "CE",
        },
        nome_componente: "PARTE DIVERSIFICADA",
        nota_final: "CE",
        observacao: "",
        p1_letivo: {
          aulas_dadas: "CE",
          nota: "CE",
          percentual: "CE",
          presenca: "CE",
        },
        p2_letivo: {
          aulas_dadas: "CE",
          nota: "CE",
          percentual: "CE",
          presenca: "CE",
        },
        p3_letivo: {
          aulas_dadas: "CE",
          nota: "CE",
          percentual: "CE",
          presenca: "CE",
        },
        p4_letivo: {
          aulas_dadas: "CE",
          nota: "CE",
          percentual: "CE",
          presenca: "CE",
        },
      },
      height: 0,
    };
  },

  // setup() {
  //   const windowWidth = ref(window.innerWidth);
  //   const windowHeight = ref(window.innerHeight);
  //   const responsiveFont = ref();

  //   const handleResize = () => {
  //     windowWidth.value = window.innerWidth;
  //     windowHeight.value = window.innerHeight;
  //   };

  //   onMounted(() => {
  //     window.addEventListener("resize", handleResize);
  //     if (windowWidth.value <= 1280) {
  //       responsiveFont.value = "font-size: 10px";
  //     }
  //   });

  //   onUnmounted(() => {
  //     window.removeEventListener("resize", handleResize);
  //   });
  //   return { responsiveFont };
  // },
  // beforeMount() {
  //   this.dados.forEach((element) => {
  //     element.componentes_curriculares.push(this.parteDiversificada);
  //     element.componentes_curriculares.sort((a, b) =>
  //       a.area < b.area ? 1 : b.area < a.area ? -1 : 0
  //     );
  //   });
  // },
});
</script>

<style scoped>
/* .responsive-font {
  font-size: 10px;
} */
.borda-externa {
  border: 2px #000 solid;
}
.borda-right {
  border-right: 2px #000 solid;
  /* width: 50%; */
}
.borda-right-1px {
  border-right: 1px solid #000;
}
.borda-bottom {
  border-bottom: 2px #000 solid;
}
.borda-bottom-1px {
  border-bottom: 1px solid #000;
}
.borda-top-1px {
  border-top: 1px #000 solid;
}
.borda-top {
  border-top: 2px #000 solid;
}
.bordas {
  border: 1px solid #000;
}

.title-padding {
  padding: 0px 5px 0px 5px;
}
.tabela {
  max-height: 2px;
  /* margin-bottom: 30px;
  border-collapse: collapse; */
}

.button-generate {
  margin-top: 10px;
  margin-right: 5px;
}
.select {
  appearance: revert;
  width: 100%;
}
.uppercase {
  text-transform: uppercase;
}
.tamanho-titulos-dias {
  width: 3%;
}
.alinhamento {
  margin-top: -15px;
  padding: 14px;
}

.alinhameneto-margem {
  margin-left: -12px;
  margin-right: -12px;
}

.card-filtro {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  margin-top: 15px;
}
</style>
