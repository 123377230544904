<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />
    <div class="" style="margin-top: -15px; padding: 14px">
      <div class="row">
        <div class="col-md-10">
          <h2 class="titulo-links-escolha">Ficha Individual</h2>
        </div>
      </div>

      <painelFiltroV3
        modelo="0"
        individual="1"
        :objeto="true"
        @buscarRelatorio="buscarRelatorio"
        :filtro="filtro"
      />
    </div>
    <!-- <div class="pm-card">
      <div class="p-fluid formgrid grid" v-if="visivel">
        <modeloPai :dados="dados" modelo="1" />
      </div>
    </div> -->
    <button
      class="btn btn-danger button-generate"
      @click="pdf()"
      title="Gerar Impressão"
      v-if="dados.length > 0"
    >
      <i class="fa fa-file-pdf-o"></i> Pdf</button
    >
    <button class="btn btn-success button-generate" @click="gerarExcel()" style="margin-top:10px;"
      v-if="dados.length > 0"
      title="Gerar Excel">
      <i class="fa fa-file-excel-o"></i> Excel
    </button>

    <button class="btn btn-secondary button-generate" @click="gerarCsv('dadosrel')" style="margin-top:10px;"
      v-if="dados.length > 0"
      title="Gerar csv">
      <i class="fa fa-file-o"></i> CSV
    </button>
    <br />

    <div class="mt-5 flex justify-content-center" v-if="isLoading">
      <pm-ProgressSpinner />
    </div>

    <div id="dadosrel" v-if="dados.length > 0 && !isLoading" class="mt-5">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
        <meta charset="utf-8">
      </head>

      <fundamentalComponent
        :cabecalho="cabecalho"
        :dados="this.dados"
        :info="info"
        :key="componentKey"
      />

      <ejaComponent
        :cabecalho="cabecalho"
        :dados="this.dados"
        :info="info"
        :key="componentKey"
      />
      <educacaoInfantilComponent
        :cabecalho="cabecalho"
        :dados="this.dados"
        :info="info"
        :key="componentKey"
      />
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import painelFiltroV3 from "./components/filtros/painelFiltroV3.vue";
import { FichaIndividual } from "./services/fichaindividual.service";
import { SeriesEscolar } from "@/class/serie";

import ejaComponent from "./components/fichaindividual/eja.vue";
import educacaoInfantilComponent from "./components/fichaindividual/educacaoInfantil.vue";
import fundamentalComponent from "./components/fichaindividual/fundamental.vue";
import axios from "axios";

export default defineComponent({
  components: {
    painelFiltroV3,
    ejaComponent,
    fundamentalComponent,
    educacaoInfantilComponent
  },
  data() {
    return {
      base_url: axios.defaults.baseURL.replace("/api/v1", ""),
      home: { icon: "pi pi-home", to: "/relatorios/ficha-individual" },
      items_bread: [{ label: "Ficha Individual" }],
      visivel: false,
      filtro: [
        {
          nome: "Nome",
          check: true,
          _id: 0,
        },
        {
          nome: "Filiação",
          check: true,
          _id: 1,
        },
      ],
      dados: [],
      info: {
        aluno:'',
        escola:0,
        segmento_id: 0,
        semestre: 1,
        serie_id: 0,
        turma_id: {
          id:0,
          semestre:1
        },
      },
      series: [],
      componentKey: 0,
      cabecalho: {},
      isLoading: false,
      prevRoute: null,
    };
  },

  methods: {
    gerarCsv(id) {
      // Obtém a div pelo id
      const div = document.getElementById(id);
      if (!div) {
        console.error('Div não encontrada: ' + id);
        return;
      }

      // Obtém todas as linhas da div
      const linhas = div.querySelectorAll('tr');

      // Cria o conteúdo do CSV
      let csv = '';
      linhas.forEach((linha) => {
        const celulas = linha.querySelectorAll('td');
        const valores = Array.from(celulas).map((celula) => celula.textContent);
        csv += valores.join(',');
        csv += '\n';
      });

      // Cria um Blob com o conteúdo do CSV
      const csvString = "\uFEFF" + csv;
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

      // Cria uma URL para o Blob
      const url = URL.createObjectURL(blob);

      // Cria um link para download do arquivo CSV
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fichaIndividual.csv');

      // Adiciona o link ao corpo do documento e inicia o download
      document.body.appendChild(link);
      link.click();

      // Remove o link do corpo do documento
      document.body.removeChild(link);
    },
    gerarExcel(){
      const div = document.getElementById('dadosrel');
      const divp = document.getElementById("printme");

      if (div != null) {
        divp.innerHTML = div.innerHTML;
      }

      const a = document.createElement('a');
      const data_type = 'data:application/vnd.ms-excel';
      const table_html = divp.outerHTML.replace(/ /g, '%20');

      a.href = data_type + ', ' + table_html;
      a.download = 'fichaindividual.xls';
      a.click();
    },
    pdf() {
      const div = document.getElementById("dadosrel");
      const divp = document.getElementById("printme");
      if (divp.innerHTML) {
        divp.innerHTML = "";

      }
      if (div != null) {
        divp.innerHTML = div.innerHTML;
      }

      window.print();
    },
    async getFichaIndividual(turmaId, alunoId, semestre, nomeAluno, ano, segmentoId, serieId, escolaId)  {
      try {
        this.dados = [];
        this.cabecalho = {};
        this.isLoading = true;
        const data = await FichaIndividual.buscaFichaIndividual(
          turmaId,
          alunoId,
          semestre,
          nomeAluno,
          ano,
          segmentoId,
          serieId,
          escolaId,
        );
        if (data.data.code === 404) {
          setTimeout(() => {
            this.$vaToast.init({
              message: "Não tem nenhum aluno com esse nome na turma",
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 3500,
              fullWidth: false,
              color: "danger",
            });
          }, 500);
        }
        if (!data.data.fichas[0].componentes_curriculares[0].p1_letivo && semestre === 1) {
          setTimeout(() => {
            this.$vaToast.init({
              message: "Nenhuma informação do(s) aluno(s) nessa turma.",
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 3500,
              fullWidth: false,
              color: "danger",
            });
          }, 500);
          this.isLoading = false;
          return false;
        } else if(!data.data.fichas[0].componentes_curriculares[0].p3_letivo && semestre === 2){
          setTimeout(() => {
            this.$vaToast.init({
              message: "Nenhuma informação do(s) aluno(s) nessa turma.",
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 3500,
              fullWidth: false,
              color: "danger",
            });
          }, 500);
          this.isLoading = false;
        }

        if (
          Object.keys(data.data.fichas[0].info_disciplina_por_area).length > 0 || this.info.segmento_id.tipo === 0
        ) {
          this.dados = data.data.fichas;
          this.cabecalho = data.data.cabecalho[0];
          this.componentKey += 1;
          this.isLoading = false;
        } else {
          setTimeout(() => {
            this.$vaToast.init({
              message: "Aluno(s) sem nota.",
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 3500,
              fullWidth: false,
              color: "danger",
            });
          }, 500);
          this.isLoading = false;
        }
        this.isLoading = false;
      } catch (error) {
        if (error.response.status === 404) {
          this.$vaToast.init({
              message: "Aluno não encontrado",
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 3500,
              fullWidth: false,
              color: "danger",
            });
        }
        this.isLoading = false;
      }
    },

    async buscarRelatorio(info) {
      this.info = info;

      // const idsAlunos = []

      // for (const item of this.info.aluno) {
      //   idsAlunos.push(item.id)
      // }


      // if (
      //   this.info.escola != 0 &&
      //   this.info.segmento_id != 0 &&
      //   this.info.serie_id != null &&
      //   this.info.turma_id != null
      // ) {
        if (this.info.aluno === '') {
          this.info.aluno = 0;
          await this.getFichaIndividual(
            this.info.turma_id.id,
            0,
            this.info.semestre,
            this.info.aluno,
            this.info.ano,
            this.info.segmento_id.id,
            this.info.serie_id.id,
            this.info.escola.id,
          );
        } else {
          await this.getFichaIndividual(
            this.info.turma_id.id,
            0,
            this.info.semestre,
            this.info.aluno,
            this.info.ano,
            this.info.segmento_id.id,
            this.info.serie_id.id,
            this.info.escola.id,
          );
        }
      // } else {
      //   setTimeout(() => {
      //     this.$vaToast.init({
      //       message: "Preencha todos os filtros",
      //       iconClass: "fa-star-o",
      //       position: "top-right",
      //       duration: 3500,
      //       fullWidth: false,
      //       color: "danger",
      //     });
      //   }, 500);
      // }
    },
  },

  // beforeMount() {

  // },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
      const divAta = document.getElementById("print");

      if ((vm.prevRoute.name === "atas" || vm.prevRoute.name === "ataAlunos") && divAta) {
        location.reload();
      }
    })
  },
});
</script>

<style scoped>
.img {
  width: 100%;
}
.borda-externa {
  border: 2px #000 solid;
}

.button-generate {
  margin-top: 10px;
  margin-right: 5px;
}
.select {
  appearance: revert;
  width: 100%;
}
/* .cor-titulo {
  background-color: #292828 !important;
  color: #fff;
} */

.uppercase {
  text-transform: uppercase;
}
.tamanho-titulos-dias {
  width: 3%;
}
.alinhamento {
  margin-top: -15px;
  padding: 14px;
}

.alinhameneto-margem {
  margin-left: -12px;
  margin-right: -12px;
}

.card-filtro {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  margin-top: 15px;
}
</style>

<style>
p {
  margin-bottom: 0px !important;
}
#noprint {
  display: block;
}
#printme {
  display: none;
}

@media print {
  @page {
    /* size: A4 landscape !important; */
    margin-top: 5px !important;

    /* margin-right: 5px; */
  }
  .table-separate{
    page-break-after: always !important;
  }
  .font {
    font-size: 17px !important;
  }
  .font-cabecalho {
    font-size: 20px !important;
  }
  #noprint {
    display: none;
  }
  #printme {
    display: block;
  }
}
</style>
